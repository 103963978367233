ion-title span {
  font-size: 0.8rem;
  background: var(--ion-color-primary-contrast);
  color: var(--ion-color-primary);
  padding: 2px 2px 1px;
  margin-right: 4px;
  position: relative;
  top: -1px;
  left: -1px;
  border-radius: 2px;
}

ion-icon.menu-icon {
  border-radius: 4px;
  padding: 3px;
  font-size: 1rem;

  &.color-danger {
    background-color: var(--ion-color-danger);
    color: var(--ion-color-danger-contrast);
  }

  &.color-primary {
    background-color: var(--ion-color-primary);
    color: var(--ion-color-primary-contrast);
  }

  &.color-success {
    background-color: var(--ion-color-success-shade);
    color: var(--ion-color-success-contrast);
  }

  &.color-warning {
    background-color: var(--ion-color-warning);
    color: var(--ion-color-warning-contrast);
  }

  &.color-medium {
    background-color: var(--ion-color-medium);
    color: var(--ion-color-medium-contrast);
  }

  &.color-tertiary {
    background-color: var(--ion-color-tertiary);
    color: var(--ion-color-tertiary-contrast);
  }
}

.print-modal-payment,
.print-modal-slip {
  align-items: flex-end;
}

.print-modal-slip > .modal-wrapper {
  --height: 400px;
}

.print-modal-payment::part(content) {
  --height: 350px;
}

.half-modal {
  align-items: flex-end;

  &::part(content) {
    transition: height 420ms;
    height: 400px !important;
  }

  &::part(backdrop) {
    --backdrop-opacity: var(--ion-backdrop-opacity, 0.4);
  }

  ion-header ion-toolbar:first-of-type {
    padding-top: 0;
  }

  &.half-modal-keyboard {
    .modal-wrapper {
      height: 100% !important;
    }

    ion-header ion-toolbar:first-of-type {
      padding-top: var(--ion-safe-area-top, 0);
    }
  }
}

ion-item {
  & > ion-text[slot='start'] {
    color: var(--ion-color-medium-tint);
    min-width: 52px;
    text-align: right;
    margin-right: 6px;
    position: relative;
    left: -6px;
  }
  *[required] label .label-text::before,
  *[required]::part(label)::before {
    content: '必須';

    background-color: var(--ion-color-danger);
    color: var(--ion-color-danger-contrast);
    font-size: 0.7rem;
    padding: 2px 5px;
    margin-right: 0.5rem;
    border-radius: 3px;
    position: relative;
    top: -1px;
  }

  *:not([required])[readonly] label .label-text::before,
  *:not([required])[readonly]::part(label)::before,
  *[disabled] label .label-text::before,
  *[disabled]::part(label)::before {
    content: none;
    background-color: transparent;
    color: transparent;
    padding: 0;
    margin: 0;
  }

  *:not([required]) label .label-text::before,
  *:not([required])::part(label)::before {
    content: '任意';

    background-color: var(--ion-color-medium);
    color: var(--ion-color-medium-contrast);
    font-size: 0.7rem;
    padding: 2px 5px;
    margin-right: 0.5rem;
    border-radius: 3px;
    position: relative;
    top: -1px;
  }
}

.input-label-placement-fixed label .label-text {
  min-width: 120px !important;
}

ion-grid.ion-grid-item2 ion-row {
  ion-col {
    padding-top: 0;
    padding-bottom: 0;

    &:first-child {
      max-width: 100px;
      flex-shrink: 0;
    }
    &:last-child {
      flex: 1;
    }
  }
}
