@media print {
  body,
  ion-page,
  .ion-page,
  ion-app,
  ion-router-outlet,
  ion-split-pane {
    contain: none !important;
    overflow: auto !important;
    height: auto !important;
    max-height: none !important;

    --offset-bottom: unset !important;
    //display: unset !important;
    position: static !important;
  }
  ion-content,
  ion-content::part(scroll) {
    contain: none !important;
    overflow: auto !important;
    height: auto !important;
    max-height: none !important;

    --offset-bottom: unset !important;
    display: unset !important;
    position: static !important;
  }
  ion-menu,
  ion-header,
  ion-toolbar {
    display: none;
  }
}
