ion-toolbar {
  --background: var(--ion-background-color, #fff) !important;
  ion-title {
    font-size: 1rem;
    font-weight: normal;

    &[size='large'] {
      font-weight: bold;
    }
  }
  ion-buttons {
    * {
      font-size: 1rem !important;
    }

    ion-button ion-icon[slot='icon-only'] {
      font-size: 1.2rem !important;
    }

    ion-button ion-icon[slot='icon-only'][name='close-outline'] {
      font-size: 1.6rem !important;
    }

    ion-back-button {
      //font-size: 0.9rem !important;
      //margin-left: 8px;
      //&.ios {
      //  --icon-padding-end: 24px !important;
      //}
      //&.md {
      //  --icon-padding-end: 0;
      //}
    }

    ion-button[fill='outline'] {
      font-size: 0.9rem !important;
      max-height: 26px;
      min-width: 56px;
    }
  }
  ion-segment {
    padding: 0;
  }
}

ion-list.list-inset {
  background: transparent;

  &.md:not(:last-child) {
    margin-bottom: 0;
  }

  ion-list-header {
    &.md {
      margin-top: 0;
    }
    ion-label {
      font-size: 0.9rem;
      font-weight: normal;
      margin: 8px 0 4px;
    }
    ion-button {
      margin-top: 8px;
    }
  }

  ion-item-group.ios,
  ion-reorder-group.ios {
    border-radius: 10px;
    overflow: hidden;
  }

  & > ion-item > ion-list > ion-item {
    // 入れ子にしてるコンテンツ
  }

  & > ion-note {
    color: var(--ion-color-medium-tint);
    font-size: 0.9rem;
    display: block;
    margin: 8px 16px;
  }
}

ion-alert {
  &.md {
    --min-width: 260px !important;
  }
  .alert-title,
  .alert-button {
    font-size: 1rem !important;
  }
  .alert-sub-title {
    font-size: 0.9rem !important;
  }
  .alert-message {
    padding-top: 8px !important;
    text-align: left !important;
    font-size: 1rem !important;

    &:empty {
      padding: 0 0 8px !important;
    }
  }
}

cdk-virtual-scroll-viewport {
  height: 100%;
  width: 100%;
  .cdk-virtual-scroll-content-wrapper {
    max-width: 100%;
  }
}

ion-grid {
  max-width: 100%;
}

/**
 * 本システムオリジナルのスタイル
 */
ion-fab[vertical='bottom'][horizontal='center'][edge='true'] {
  ion-fab-button {
    --background: var(--ion-color-primary-contrast);
    --color: var(--ion-color-primary);
    --color-activated: var(--ion-color-primary-contrast);
    --box-shadow: 0 0 0 2px var(--ion-color-primary);
  }
}

ion-footer {
  ion-toolbar > section {
    h2,
    h3 {
      font-size: 1rem;
    }
  }
}

.ion-content-scroll-host {
  ion-item ion-row {
    ion-col {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
}

ion-menu {
  max-width: 320px;
}

ion-title.title-large {
  font-size: 30px;
}

ion-button.medium-size {
  min-width: 240px;
}

ion-chip {
  pointer-events: none;
}
